import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export interface ApiClientConfig {
   /**
    * Required when initialized server side.
    * Defaults to the current url when initialized in a browser.
    */
   baseUrl?: string;

   baseOptions?: AxiosRequestConfig;

   /**
    * Required when initialized server side.
    * Cannot be used when initialized in a browser.
    */
   session?: Session;
}

export interface Session {
   sessionName: string;
   sessionValue: string;
}

export class ApiClient {
   private readonly baseUrl: string;
   private readonly baseOptions: AxiosRequestConfig;
   private readonly session?: Session;

   constructor({ baseUrl, baseOptions = {}, session }: ApiClientConfig = {}) {
      this.baseUrl = this.validateBaseUrl(baseUrl);
      this.baseOptions = baseOptions;
      this.session = this.validateSession(session);
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   async get<T = any>(path: string, options: AxiosRequestConfig = {}) {
      return axios.get<T>(this.getUrl(path), this.getOptions(options));
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   async post(path: string, data?: any, options: AxiosRequestConfig = {}) {
      return axios.post(this.getUrl(path), data, this.getOptions(options));
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   async put(path: string, data?: any, options: AxiosRequestConfig = {}) {
      return axios.put(this.getUrl(path), data, this.getOptions(options));
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   async patch(path: string, data?: any, options: AxiosRequestConfig = {}) {
      return axios.patch(this.getUrl(path), data, this.getOptions(options));
   }

   async delete(path: string, options: AxiosRequestConfig = {}) {
      return axios.delete(this.getUrl(path), this.getOptions(options));
   }

   async request(
      method: 'get' | 'post' | 'patch' | 'put' | 'delete',
      path: string,
      options: AxiosRequestConfig = {}
   ) {
      return axios.request({
         url: this.getUrl(path),
         method: method,
         ...this.getOptions(options),
      });
   }

   getBaseUrl(): string {
      return this.baseUrl;
   }

   getOptions(requestOptions: AxiosRequestConfig = {}): AxiosRequestConfig {
      const cookieOverrideString = this.getCookieOverrideString();

      const headers = {
         // This enables the API over HTTP so browsers without CORS can use it.
         'X-ALLOW-HTTP': true,
         // Identifies this request for API stat purposes.
         'Api-Client': 'iFixit-Web',
         'content-type': 'application/json',
         ...this.baseOptions.headers,
         ...requestOptions.headers,
         cookie:
            cookieOverrideString ??
            (this.baseOptions.headers?.cookie || requestOptions.headers?.cookie),
      };

      return {
         ...this.baseOptions,
         ...requestOptions,

         // Do this after spreading options so that the default headers aren't overwritten if
         // additional headers are set
         headers,
      };
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   static isApiError(error: any): error is AxiosError {
      return Boolean(error?.isAxiosError);
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   static isInvalidLoginError(error: any): error is AxiosError {
      return ApiClient.isApiError(error) && error.response?.status === 401;
   }

   private getUrl(path: string): string {
      // Remove leading slash if it exists
      path = path.replace(/^\//, '');
      return new URL(`api/2.0/${path}`, this.baseUrl).toString();
   }

   private getCookieOverrideString(): string | null {
      if (this.session) {
         return `${this.session.sessionName}=${this.session.sessionValue};`;
      }

      return null;
   }

   private validateBaseUrl(baseUrl?: string): string {
      if (typeof window !== 'undefined') {
         return baseUrl || window.location.origin;
      } else if (!baseUrl) {
         // eslint-disable-next-line @ifixit/no-new-error
         throw new Error('ApiClient must be initialized with a baseUrl when used server side.');
      }
      return baseUrl;
   }

   private validateSession(session?: Session): Session | undefined {
      if (typeof window !== 'undefined' && session) {
         // eslint-disable-next-line @ifixit/no-new-error
         throw new Error('ApiClient can only override the session when used server side.');
      }

      return session;
   }
}
